<template>
  <div class="content_block">
    <div class="top">
      <b>近三年业绩信息</b>
      <template v-if="!isDisabled">
        <div class="btnContainer" v-if="isEditing">
          <el-button type="default" size="mini"  @click="handleCancel" v-if="!isShowHead">取消</el-button>
          <el-button type="primary" size="mini" @click="handleSave" v-if="!isShowHead">保存</el-button>
          <el-button type="primary" size="mini" @click="handleUpdate" v-else>保存</el-button>
        </div>
        <div class="btnContainer" v-else>
          <el-button type="primary" plain icon="el-icon-edit" size="mini" @click="handleEdit">编辑</el-button>
        </div>
      </template>
    </div>
    <template v-if="isEditing">
      <el-form :model="form" ref="JZForm" :rules="rules" label-width="140px" label-position="right">
        <el-row>
          <el-col :span="12">
            <el-form-item label="近三年销售额" prop="salesOfThreeYears">
              <el-input v-model.trim="form.salesOfThreeYears" placeholder="请输入近三年销售额">
                <template slot="append">万元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="销售合同附件" prop='tenantFileReqDTOs'>
              <Upload
                v-model="form.tenantFileReqDTOs"
                label="销售合同附件"
                buttonText="上传"
                type="single"
                :show-file-list="false"
                @success="handleFileLists"
                @remove="handleRemove"
                accept="image/png,image/jpeg,image/jpg,application/pdf"
                :maxSize="10"
                size="origin"
                :limit="10"
              >
              </Upload>
            </el-form-item>
          </el-col>
          <el-col :span="24"  v-if="form.tenantFileReqDTOs&&form.tenantFileReqDTOs.length">
            <el-form-item label="">
              <ul class="imgContainer">
                <li v-for="(img,index) in form.tenantFileReqDTOs" :key="img.url">
                  <div class="left">
                    <el-link type="primary" :href="img.url" :underline="false" target="blank">
                    <span class="name" :title='img.name'><img :src="require('@/assets/pdf.svg')" />{{ img.name }}</span>
                  </el-link>
                  </div>
                  <div class="right">
                    <i class="el-icon-success" style="color:#67C23A;margin-right: 8px;font-size:16px;"></i>
                    <i class="el-icon-delete" style="color:#409EFF;font-size:16px;cursor: pointer;" @click="handleDelete(index)"></i>
                  </div>
                </li>
              </ul>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </template>
    <el-descriptions :colon="false" :column="2" style="margin-top: 10px;" v-else>
      <el-descriptions-item label="近三年销售额" :span="2" labelClassName="warningLabel" contentClassName="warningContent">
        {{ form.salesOfThreeYears }}万元
      </el-descriptions-item>
      <el-descriptions-item label="销售合同附件" :span="2" labelClassName="warningLabel" contentClassName="warningContent">
        <div v-for="img in form.tenantFileReqDTOs" :key="img.url" class="fileContainer">
          <i class="el-icon-document" style="color:#FF5959;margin-right:4px;"></i><el-link type="primary" :href="img.url" :underline="false" target="blank"> {{ img.name }}</el-link>
        </div>
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
export default {
  name: '',
  components: {
    Upload: ()=>import('@/components/common/upload'),
  },
  props: {
    serviceInfo: {
      type: Object,
      default () {
        return {};
      },
    },
    isDisabled: {
      type: Boolean,
      default () {
        return false;
      },
    },
    isShowHead: {
      type: Boolean,
      default () {
        return false;
      },
    },
  },
  directives: {
    LimitInputNumber: {
      bind (el) {
        el.oninput = () => {
          el.children[0].value = el.children[0].value.replace(/[^\d.]/g, '');
        };
      },
    },
  },
  watch: {
    isShowHead: {
      handler (val) {
        if (val) {
          this.isEditing = this.isShowHead;
        }
      },
      immediate: true,
      deep: true,
    },
    serviceInfo: {
      handler (val) {
        if (val) {
          val = JSON.parse(JSON.stringify(val));
          let tenantFileReqDTOs = (val.tenantFileReqDTOs || []).filter(i=>i.type === '17');
          this.form = {
            salesOfThreeYears: val.salesOfThreeYears || '',
            tenantFileReqDTOs: tenantFileReqDTOs || [],
          };
          this.clearValidate();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    clearValidate () {
      this.$nextTick(()=>{
        this.$refs.JZForm && this.$refs.JZForm.clearValidate();
      });
    },
    handleEdit () {
      this.isEditing = true;
    },
    handleCancel () {
      this.isEditing = false;
    },
    handleSave () {
      this.$refs.JZForm.validate(valid =>{
        if (valid) {
          let info = this.form;
          let tempLists = this.serviceInfo.tenantFileReqDTOs.filter(i=>i.type === '16');
          let tenantFileReqDTOs = info.tenantFileReqDTOs.map(i => ({
            ...i,
            type: '17',
          }));
          tenantFileReqDTOs = [...tenantFileReqDTOs, ...tempLists];
          info = {
            ...info,
            tenantFileReqDTOs: tenantFileReqDTOs,

          };
          this.$emit('updateService', info);
          this.isEditing = false;
        }
      });
    },
    handleUpdate () {
      this.$refs.JZForm.validate(valid =>{
        if (valid) {
          let info = this.form;
          let tempLists = this.serviceInfo.tenantFileReqDTOs.filter(i=>i.type === '16');
          let tenantFileReqDTOs = info.tenantFileReqDTOs.map(i => ({
            ...i,
            type: '17',
          }));
          tenantFileReqDTOs = [...tenantFileReqDTOs, ...tempLists];
          info = {
            ...info,
            tenantFileReqDTOs: tenantFileReqDTOs,

          };
          this.$emit('updateService', info);
        }
      });
    },
    handleRemove () {
      console.log(arguments[0]);
    },
    handleFileLists () {
      console.log(arguments[0]);
    },
    handleDelete (index) {
      this.form.tenantFileReqDTOs.splice(index, 1);
    },
  },
  data () {
    return {
      isEditing: false,
      rules: {
        tenantFileReqDTOs: [
          {
            required: false,
            message: '请选择附件信息',
            trigger: ['blur', 'change'],
          },
        ],
        salesOfThreeYears: [
          {
            required: true,
            message: '请输入近三年销售额',
            trigger: ['blur', 'change'],
          },
          {
            // pattern: /(^[\d]|^[1-9][\d]*)($|[\.][\d]{0,6}$)/,
            pattern: /^(([1-9]{1}\d*)|(0{1}))(1\.\d{0,6})?$/,
            message: '销售额只能输入数字与小数',
            trigger: ['blur', 'change'],
          },
        ],
      },
      form: {
        salesOfThreeYears: '',
        tenantFileReqDTOs: [],
      },
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
.content_block{
  padding: 0 20px 20px 20px;
  min-height: calc(100vh - 420px);
  .top{
    height: 40px;
    line-height: 40px;
    display: flex;
    .btnContainer{
      flex: 1;
      text-align:right;
    }
  }
}
/deep/ .warningLabel{
  width: 110px;
  text-align: right;
  display: inline-block;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #929AA6;
  letter-spacing: 0;
  text-align: right;
  line-height: 20px;
}
/deep/ .warningContent{
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #1F2733;
  letter-spacing: 0;
  text-align: left;
  line-height: 20px;
  display: flex;
  flex-direction: column;
}
.imgContainer{
  list-style: none;
  li{
    height: 40px;
    line-height: 20px;
    background: #F5F7FA;
    border-radius: 4px;
    display: flex;
    width:500px;
    padding: 10px 8px;
    margin-bottom: 8px;
    .left{
      flex: 1;
      display: flex;
      .el-link.el-link--primary {
        width:440px;
        display:inline-block;
        span.el-link--inner{
          width: 100%;
          span.name{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 420px;
            display:inline-block;
          }
        }
      }
    }
    img{
      width:16px;
      height:16px;
      margin-right: 4px;
    }
  }
}
</style>
